$base-font-size: 12px;
$title-font-size: 1.3em;

$primary-color-dark: #e64a19;
$primary-color: #008489;
$primary-color-light: #018f95;
$primary-color-text: #ffffff;
$accent-color: #008489;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #e4e4e4;
$today-bg-color: #ffffff;

@import "../node_modules/@y0c/react-datepicker/assets/styles/_mixin.scss";
@import "../node_modules/@y0c/react-datepicker/assets/styles/app.scss";

#test1 .calendar {
  width: 90% !important;
}
#test1 .calendar__container {
  width: 100%;
}
.calendar__day--selected {
  text-decoration: line-through;
  background: #b9b1ae;
}

#test1 .calendar__body--table th {
  height: 60px;
}

#test1 .calendar__day {
  vertical-align: middle;
  padding: 40px;
  font-size: 20px;
  border: 1px solid #e5e5e5;
}
#test1 .calendar__head {
  padding: 15px 6px;
}

.calendar__day--range {
  background: #66e2da;
  color: #ffffff;
}

.calendar__day {
  border: 1px solid #f1f1f1;
}

.calendar__day:hover,
.calendar__day:focus {
  //   background: #b0f7f3;
  background: #e93a53;
  color: #ffffff;
}

.calendar__day:hover:not(.calendar__day--disabled) {
  background: #e93a53;
  color: #ffffff;
}

.calendar__day--6,
.calendar__day--0 {
  color: $primary-text-color;
}
.calendar__body--table th {
  font-weight: normal;
  letter-spacing: 0.7px;
  font-family: "Cereal-book";
  height: 45px;
}

.calendar__head--button {
  border: 0px !important;
}
.calendar__container {
  width: 35em;
}
// .picker__container  {
//     position:sticky;
// }

.calendar__head {
  background-color: #e93a53;
}

.calendar__item {
  border: 1px solid #170f161c !important;
}

.calendar__day {
  vertical-align: top;
  padding: 1em;
  /* height: 47px; */
  cursor: pointer;
  width: 100px;
}

.booking-create .calendar__container {
  width: 20em !important;
}
